/*CtaTeaser*/
.CtaTeaser {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  &.aspect16by9:before {
    padding-top: 56.25%;
  }
  &.aspect4by3:before {
    padding-top: 75%;
  }
  &.aspect3by2:before {
    padding-top: 66.6666%;
  }
  &.aspect9by16:before {
    padding-top: 177.7777777777778%;
  }
  &.aspect3by4:before {
    padding-top: 133.3333333333333%;
  }
  &.aspect2by3:before {
    padding-top: 150%;
  }
  &.aspect1by2:before {
    padding-top: 200%;
  }
  .CtaTeaserinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .CtaTeasertext {
    text-align: center;
    color: #fff;
    text-shadow: 0 0 2px #000;
    line-height: 1.7em;
    font-size: 15px;
  }
  .CtaTeasertext h2 {
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 2rem;
  }
  .btn {
    position: relative;
    font-weight: 700;
  }
}
